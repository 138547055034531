<div
    class="alert alert-dismissible fade show"
    [class]="'alert-' + type()"
    role="alert">
    @if (type() === "danger") {
        <strong>Oh no!</strong>
    }
    {{ message() }}
    <button
        type="button"
        ng-show="notification.showClose"
        class="close"
        (click)="dismiss.emit()">
        &times;
    </button>
</div>
