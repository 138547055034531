import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { UpgradeModule } from "@angular/upgrade/static";
import { Observable } from "rxjs";

export function authInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    const upgrade = inject(UpgradeModule);
    const appConfig = upgrade.$injector.get("appConfig");
    const authService = upgrade.$injector.get("Auth0Service");

    if (!request.url.includes("/api")) return next(request);

    const accessToken = authService.getAccessToken();

    return next(
        request.clone({
            setHeaders: {
                ["Authorization"]: `Bearer ${accessToken}`
            }
        })
    );
}
