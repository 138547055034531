import { Component, input, output } from "@angular/core";

type NotificationAlertType = "success" | "info" | "warning" | "danger";

@Component({
    selector: "kno2-notification-alert",
    standalone: true,
    templateUrl: "./notification-alert.component.html"
})
export class NotificationAlertComponent {
    public type = input.required<NotificationAlertType>();
    public message = input.required<string>();
    public dismiss = output<void>();
}
